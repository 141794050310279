<template>
  <div class>
    <!-- 福利中心 -->
    <!-- 电脑 -->
    <div v-if="isshow">
      <div class="welfareBox">
        <div class="main">
          <div class="right d-flex justify">
            <div class="c6Bottom t-c">
              <div class="bottom">
                <div class="pwdred">
                  <img src="@/assets/images/Welfare/pwd.png" alt />
                </div>
                <div class="inp">
                  <input type="text" placeholder="请输入口令" class="input" />
                </div>
                <p class="f-13 color-g qq t-c">
                  添加qq群：398504261，天天领红包！
                </p>
                <div class="btn color-white t-c" @click="getbox">领取</div>
              </div>
            </div>
            <div class="hdzt">
              <div class="top">规则介绍</div>
              <div class="bottom">
                1.输入红包口令，可获得随机金币奖励，金币用于
                玩法的抵扣，不可直接兑换商城皮肤。
                <br />
                <br />2.口令可以通过官方QQ群、官方活动等方式获得，
                口令和红包有时效性。
                <br />
                <br />3.红包最终解释权归本平台所有，任何通过非法手段
                牟利的，都会导致资产冻结或账号封禁。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wlefareBtn"></div>
    </div>
    <!-- phone -->
    <div v-else>
      <div class="welfareBox">
        <div class="main">
          <div class="red">
            <img width="100%" src="@/assets/images/Welfare/redm.png" alt />
          </div>
          <div class="inp">
            <input type="text" placeholder="请输入口令" class="input" />
          </div>
          <p class="f-20 color-g zt t-c">添加qq群：398504261，天天领红包！</p>
          <div class="btn color-white t-c" @click="getbox()">领取</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getred, updateUserData } from "../../../api/index";
export default {
  inject: ["reload"],
  data() {
    return {
      btn: "c6",
      isshow: true,
    };
  },

  mounted() {
    if (window.innerWidth < 550) {
      this.isshow = false;
    }
  },
  methods: {
    getbox() {
      let cdk = document.getElementsByClassName("input")[0];
      getred(cdk.value).then((res) => {
        if (res.data.code == 200) {
          this.$message.success(
            `领取成功，￥${res.data.data.value},已存入余额！`
          );
          updateUserData().then((res) => {
            console.log(res);
            this.reload();
          });
        } else {
          if (res.data.msg == null) {
            this.$message.error("不能输入为空");
          } else {
            this.$message.error(res.data.msg);
          }
        }
      });
    },
    activeBtn(state) {
      this.btn = state;
    },
  },
};
</script>

<style scoped lang="scss">
.c6Bottom {
  height: 200px;
  .bottom {
    width: 412px;
    height: 200px;
    position: relative;
    background: url("~@/assets/images/Welfare/red.png") no-repeat;
    background-size: 100% 100%;
    .input {
      outline: none;
      background-color: rgba(0, 0, 0, 0);
      width: 51%;
      height: 30px;
      border: 2px solid #08376e;
      text-align: center;
      border-radius: 15px;
      position: absolute;
      top: 88px;
      right: 36px;
      color: #fff;
    }
    .qq {
      position: absolute;
      bottom: 38px;
      right: 33px;
    }
    .btn {
      width: 112px;
      height: 45px;
      background: url("~@/assets/images/Welfare/btn.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      bottom: 0;
      left: 39%;
    }
    .pwdred {
      position: absolute;
      top: 25%;
      right: 33px;
    }
  }
}
.main {
  margin: auto;
  padding: 11px;
  box-sizing: border-box;
}

.avtiveNav {
  width: 60%;
  margin: auto;
}

.c6img {
  width: 100%;
}
.hdzt {
  width: 260px;
  height: 180px;
  color: #fff;
  font-size: 12px;
  .top {
    font-size: 16px;
  }
  .bottom {
    text-align: left;
    line-height: 1.5;
  }
}
@media (max-width: 550px) {
  .main {
    margin: 20px 0;
    .input {
      outline: none;
      background-color: rgba(0, 0, 0, 0);
      width: 100%;
      height: 30px;
      border: 2px solid #08376e;
      text-align: center;
      margin: 15px auto;
      color: #fff;
      border-radius: 15px;
    }
    .btn {
      width: 112px;
      height: 45px;
      background: url("~@/assets/images/Welfare/btn.png") no-repeat;
      background-size: 100% 100%;
      margin: 10px auto;
    }
  }
}
</style>
